import type { AppRouter } from "@repo/api/src/appRouter";
import { TRPCLink } from "@trpc/client";
import { Observable, observable, share } from "@trpc/server/observable";

/**
 * This was copied from the TRPC source code.
 *
 * It's not exported by TRPC (yet) but should be good enough for now.
 *
 * TRPC says it's not necessary since react-query already dedupes requests,
 * but we're not using react-query (which sort of comes with TRPC React)
 * because Kepler.gl already brings in redux, so instead we're integrating
 * TRPC with RTK Query, which doesn't dedupe requests. It has nicer DX
 * around skip tokens, though, although that's beside the point.
 */
export function dedupeLink(): TRPCLink<AppRouter> {
  // initialized config
  return () => {
    // initialized in app
    const pending: Record<string, Observable<any, any>> = {};
    return ({ op, next }) => {
      // initialized for request

      if (op.type !== "query") {
        // pass through
        return next(op);
      }
      const key = JSON.stringify([op.path, op.input]);
      const obs$ = pending[key];
      if (obs$) {
        // console.log('hooking into pending', { op });
        return observable((observer) => obs$.subscribe(observer));
      }

      const shared$ = observable((observer) => {
        function reset() {
          delete pending[key];
        }
        const subscription = next(op).subscribe({
          next(v) {
            observer.next(v);
          },
          error(e) {
            reset();
            observer.error(e);
          },
          complete() {
            reset();
            observer.complete();
          },
        });
        return () => {
          reset();
          subscription.unsubscribe();
        };
      }).pipe(share());
      pending[key] = shared$;
      return shared$;
    };
  };
}
