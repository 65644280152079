import { csvParseRows } from "d3-dsv";
import invariant from "tiny-invariant";
import { ProtoDataset } from "@kepler.gl/types";
import {
  DimensionItem,
  LineItemMetadata,
  Model,
  Org,
  trpcClient,
  View,
  ViewMetadata,
  WriteTarget,
} from "../trpc/trpcClient";

type WritebackLineItem = LineItemMetadata & {
  postSaveProcessID: string | null;
};

/**
 * An Anaplan view with both its cell values and metadata.
 *
 * Also includes arrays to look up Anaplan line item and row IDs based
 * on Kepler dataset row and column indices.
 */
interface AnaplanDataset {
  org: Org;
  model: Model;
  view: View & ViewMetadata;
  pages: Record<string, DimensionItem>;
  cellValues: string[][];
  /** Line items, in the same order as the Kepler table columns. */
  lineItems: LineItemMetadata[];
  /**
   * To update the values of computed cells, the user needs to write to
   * a different, source module, not the module used to display the
   * data. These "overrides" are tracked here, based on line item ID.
   * If a line item ID is in this map, its value is the line item that
   * should be written to when its cell values are updated.
   */
  writeTargets: {
    [lineItemID: string]: WritebackLineItem;
  };
  /**
   * Dimension values, in the same order as the Kepler table rows.
   *
   * Each array entry will be an array of strings corresponding to the
   * values of the dimensions specified in the view's "rows"
   * configuration. Since most views only have one dimension in their
   * rows, each entry will usually be an array of one string, e.g.
   * [['100'], ['101'], ['102']].
   */
  rowItemNames: string[][];
  /**
   * Row item codes, by row index.
   *
   * Sometimes this is needed for writebacks overrides. The list item
   * names aren't unique, and Anaplan won't know what to update.
   */
  rowItemCodes?: string[];
}

export interface AddViewPayload {
  org: Org;
  model: Model;
  view: View;
  viewMetadata: ViewMetadata;
  pages: Record<string, DimensionItem>;
}

// So I can get an interface with static methods
// eslint-disable-next-line @typescript-eslint/no-redeclare
const AnaplanDataset = {
  async fromAddViewPayload(payload: AddViewPayload): Promise<AnaplanDataset> {
    const { org, model, view, viewMetadata, pages } = payload;
    const moduleLineItems = await trpcClient.anaplan.getModuleLineItems.query({
      orgID: org.id,
      modelID: model.id,
      moduleID: view.moduleId,
    });

    const cellValues = await trpcClient.anaplan.getViewData
      .query({
        orgID: org.id,
        modelID: model.id,
        viewID: view.id,
        pages: Object.fromEntries(
          Object.entries(pages).map(([dID, item]) => [dID, item.id])
        ),
      })
      .then((r) => csvParseRows(r));

    const writeTargets = await trpcClient.writeTarget.listByModule.query({
      orgID: org.id,
      modelID: model.id,
      moduleID: view.moduleId,
    });

    const writeTargetsByID: { [id: string]: WritebackLineItem } = {};
    for (const writeTarget of writeTargets) {
      const lineItem = await getWritebackLineItem(org.id, writeTarget);
      writeTargetsByID[writeTarget.readLineItemId] = lineItem;
    }

    // If there are pages, they're all on the first row, otherwise the
    // headers begin on the first row
    const nPagesRows = viewMetadata.pages?.length ? 1 : 0;
    // There is a header row for each dimension in columns.
    const nHeaderRows = viewMetadata.columns?.length ?? 0;
    // The column(s) is for the dimension values of the row.
    const nDimensionCols = viewMetadata.rows?.length ?? 0;

    // First row in cell values (after pages rows) is line item names
    // First cell(s) in cell values header row is blank, for (each) row dimension
    const colNames = cellValues[nPagesRows].slice(nDimensionCols);

    const lineItems = colNames.map(
      (colName) =>
        moduleLineItems.find((lineItem) => lineItem.name === colName)!
    );

    const dimensionValuesByRowIdx = cellValues
      .slice(nPagesRows + nHeaderRows, -1) // -1 to exclude totals row
      .map((rowWithDimensions) => rowWithDimensions.slice(0, nDimensionCols));

    let rowItemCodes: string[] | undefined = undefined;
    if (writeTargets.length) {
      const codeColumnID = writeTargets[0].readItemCodeLineItemId;
      const codeColumnIdx =
        lineItems.findIndex((item) => item.id === codeColumnID) +
        nDimensionCols;

      rowItemCodes = cellValues
        .slice(nPagesRows + nHeaderRows, -1)
        .map((row) => row[codeColumnIdx]);
    }

    return {
      org,
      model,
      view: { ...view, ...viewMetadata },
      pages,
      lineItems,
      cellValues,
      writeTargets: writeTargetsByID,
      rowItemNames: dimensionValuesByRowIdx,
      rowItemCodes,
    };
  },
  async fromDatasetURL(org: Org, url: URL): Promise<AnaplanDataset> {
    const orgID = org.id;
    const match = url.pathname.match(/models\/(.*)\/views\/(.*)\//);
    invariant(match, "URL must include /models/{id}/views/{id}");
    const [, modelID, viewID] = match;
    const models = await trpcClient.anaplan.getModels.query(orgID);
    const model = models.find((m) => m.id === modelID);
    invariant(model, `Model ${modelID} must exist`);
    const views = await trpcClient.anaplan.getViews.query({ orgID, modelID });
    const view = views.find((v) => v.id === viewID);
    invariant(view, `View ${viewID} must exist on model ${modelID}`);

    const viewMetadata = await trpcClient.anaplan.getViewMetadata.query({
      orgID,
      modelID,
      viewID,
    });

    const pagesStr = url.searchParams.get("pages") ?? "";
    const idPairs = pagesStr
      .split(",")
      .filter(Boolean)
      .map((pairStr) => {
        const [dID, itemID] = pairStr.split(":");
        return [dID, itemID] as const;
      });

    const pages: {
      [dimensionID: string]: DimensionItem;
    } = {};

    for (let [dimensionID, itemID] of idPairs) {
      const items = await trpcClient.anaplan.getDimensionItems.query({
        orgID: org.id,
        modelID,
        viewID,
        dimensionID,
      });
      const item = items.find((i) => i.id === itemID);
      if (item) {
        pages[dimensionID] = item;
      }
    }

    return AnaplanDataset.fromAddViewPayload({
      org,
      model,
      view,
      viewMetadata,
      pages,
    });
  },
  toKeplerDataset(anaplanDataset: AnaplanDataset): ProtoDataset {
    const { view, lineItems, cellValues } = anaplanDataset;
    // If there are pages, they're all on the first row, otherwise the
    // headers begin on the first row
    const nPagesRows = +!!view.pages?.length;
    // There is a header row for each dimension in columns.
    const nHeaderRows = (view.columns ?? []).length;
    // The column(s) is for the dimension values of the row.
    const nDimensionCols = (view.rows ?? []).length;

    const keplerRows = cellValues
      .slice(nPagesRows + nHeaderRows, -1)
      .map((row) =>
        row
          .slice(nDimensionCols)
          .map((cell, colIdx) => parseLineItemValue(cell, lineItems[colIdx]))
      );

    return {
      info: {
        id: AnaplanDataset.toDatasetURL(anaplanDataset).href,
        label: AnaplanDataset.toDatasetName(anaplanDataset),
      },
      data: {
        rows: keplerRows,
        fields: lineItems.map(lineItemToKeplerField),
      },
    };
  },
  toDatasetURL({ model, view, pages }: AnaplanDataset): URL {
    const url = new URL(
      `/2/0/models/${model.id}/views/${view.viewId}/data`,
      "https://api.anaplan.com"
    );
    const dimensionPairs = Object.entries(pages).map(([dID, dimensionItem]) => [
      dID,
      dimensionItem.id,
    ]);

    dimensionPairs.sort(([aID], [bID]) => aID.localeCompare(bID));

    if (dimensionPairs.length) {
      url.searchParams.set(
        "pages",
        dimensionPairs.map((pair) => pair.join(":")).join(",")
      );
    }
    return url;
  },
  toDatasetName({ view, pages }: AnaplanDataset) {
    return Object.entries(pages).length
      ? Object.entries(pages)
          .map(([, item]) => item.name)
          .join(", ")
      : view.viewName;
  },
};

export default AnaplanDataset;

const parseLineItemValue = (value: string, lineItem: LineItemMetadata) => {
  switch (lineItem.formatMetadata.dataType) {
    case "BOOLEAN":
      return value === "true";
    case "NUMBER":
      return parseFloat(value);
    // Parse using browser heuristics, convert to ISO, then clip the
    // fractional seconds and trailing 'Z'. Why? Because Kepler.gl seems
    // to use a parser that only respects weird formats:
    // https://github.com/uber-web/type-analyzer/blob/master/src/time-regex.js
    case "DATE":
      return value && new Date(value).toISOString().slice(0, 19);
    default:
      return value;
  }
};

const lineItemToKeplerField = (lineItem: LineItemMetadata) => ({
  id: lineItem.id,
  name: lineItem.name,
  // The format string was chosen after squinting at the following code
  // and having a good think:
  // https://github.com/uber-web/type-analyzer/blob/master/src/time-regex.js
  format: lineItem.formatMetadata.dataType === "DATE" ? "YYYY-M-DTH:m:s" : "",
  type: {
    DATE: "timestamp",
    BOOLEAN: "boolean",
    NUMBER:
      lineItem.formatMetadata.dataType === "NUMBER" &&
      lineItem.formatMetadata.decimalPlaces === 0
        ? "integer"
        : "real",
    TEXT: "string",
    TIME_ENTITY: "string",
    ENTITY: "string",
    NONE: "string",
  }[lineItem.formatMetadata.dataType],
});

async function getWritebackLineItem(
  orgID: string,
  writeTarget: WriteTarget
): Promise<WritebackLineItem> {
  const lineItems = await trpcClient.anaplan.getModuleLineItems.query({
    orgID,
    modelID: writeTarget.modelId,
    moduleID: writeTarget.writeModuleId,
  });
  const lineItem = lineItems.find((l) => l.id === writeTarget.writeLineItemId);
  invariant(lineItem, "Line item not found");
  return {
    ...lineItem,
    postSaveProcessID: writeTarget.postSaveProcessId,
  };
}
