import { FC, PropsWithChildren, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
import { User, UserManager } from "oidc-client-ts";

const authorityURL = new URL(process.env.REACT_APP_OIDC_AUTHORITY!);

export const userManager = new UserManager({
  authority: authorityURL.origin + authorityURL.pathname,
  client_id: authorityURL.username,
  redirect_uri: `${window.location.origin}/oauth2/callback`,
  metadataSeed: {
    end_session_endpoint: process.env.REACT_APP_LOGOUT_URL,
  },
});

export const useSigninCallback = () => {
  const navigate = useNavigate();
  return useCallback(
    (user: User | void) => {
      const state: { redirectTo?: string } = user?.state ?? {};
      const redirectTo = state.redirectTo ?? "/";
      navigate(redirectTo);
    },
    [navigate]
  );
};

const AppAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const onSigninCallback = useSigninCallback();

  return (
    <AuthProvider
      scope="openid email profile"
      onSigninCallback={onSigninCallback}
      userManager={userManager}
    >
      {children}
    </AuthProvider>
  );
};

export default AppAuthProvider;
