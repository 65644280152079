import type { AppRouter } from "@repo/api/src/appRouter";
import type { inferProcedureOutput, inferProcedureInput } from "@trpc/server";
import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import superjson from "superjson";
import { userManager } from "../app/AppAuthProvider";
import { dedupeLink } from "./dedupeLink";

export type Status = inferProcedureOutput<AppRouter["status"]["get"]>;
export type Org = inferProcedureOutput<AppRouter["org"]["get"]>;
export type Model = inferProcedureOutput<
  AppRouter["anaplan"]["getModels"]
>[number];
export type View = inferProcedureOutput<
  AppRouter["anaplan"]["getViews"]
>[number];
export type LineItemMetadata = inferProcedureOutput<
  AppRouter["anaplan"]["getModuleLineItems"]
>[number];
export type ViewMetadata = inferProcedureOutput<
  AppRouter["anaplan"]["getViewMetadata"]
>;
export type Dimension = NonNullable<ViewMetadata["pages"]>[number];
export type DimensionItem = inferProcedureOutput<
  AppRouter["anaplan"]["getDimensionItems"]
>[number];
export type SetCellsResponse = inferProcedureOutput<
  AppRouter["anaplan"]["setCells"]
>;
export type SetCellPayload = inferProcedureInput<
  AppRouter["anaplan"]["setCells"]
>["cells"][number];
export type WriteTarget = inferProcedureOutput<
  AppRouter["writeTarget"]["listByModule"]
>[number];
export type TaskStatus = inferProcedureOutput<
  AppRouter["anaplan"]["getTaskStatus"]
>;
export type ListItems = inferProcedureOutput<
  AppRouter["anaplan"]["getListItems"]
>;

export const trpcClient = createTRPCProxyClient<AppRouter>({
  transformer: superjson,
  links: [
    dedupeLink(),
    httpBatchLink({
      url: "/trpc",
      // If the user is authenticated, add their access token to API
      // requests. If they're expired but can refresh, try that first.
      async headers() {
        let user = await userManager.getUser();
        if (user && user.expired && user.refresh_token) {
          user = await userManager.signinSilent().catch(() => null);
        }

        return user && !user.expired && user.access_token
          ? { Authorization: `Bearer ${user.access_token}` }
          : {};
      },
    }),
  ],
});
