import type {
  Model,
  Org,
  View,
  Status,
  ViewMetadata,
  DimensionItem,
  ListItems,
} from "../trpc/trpcClient";
import { createApi } from "@reduxjs/toolkit/query/react";
import { trpcClient } from "../trpc/trpcClient";

/**
 * Redux "slice" containing the API calls.
 */
export const api = createApi({
  reducerPath: "api",
  baseQuery: (trpcResult: Promise<unknown>) =>
    trpcResult.then((data) => ({ data })).catch((error) => ({ error })),
  endpoints: (builder) => ({
    getStatus: builder.query<Status, void>({
      query: trpcClient.status.get.query,
    }),
    getOrg: builder.query<Org, void>({
      query() {
        const subdomain = window.location.hostname.split(".")[0];
        return trpcClient.org.get.query(subdomain);
      },
    }),
    getModels: builder.query<Model[], string>({
      query: trpcClient.anaplan.getModels.query,
    }),
    getViews: builder.query<View[], { orgID: string; modelID: string }>({
      query: trpcClient.anaplan.getViews.query,
    }),
    getViewMetadata: builder.query<
      ViewMetadata,
      { orgID: string; modelID: string; viewID: string }
    >({
      query: trpcClient.anaplan.getViewMetadata.query,
    }),
    getDimensionItems: builder.query<
      DimensionItem[],
      { orgID: string; modelID: string; viewID: string; dimensionID: string }
    >({
      query: trpcClient.anaplan.getDimensionItems.query,
    }),
    getListItems: builder.query<
      ListItems,
      { orgID: string; modelID: string; dimensionID: string }
    >({
      query: trpcClient.anaplan.getListItems.query,
    }),
  }),
});

export const {
  useGetStatusQuery,
  useGetOrgQuery,
  useGetModelsQuery,
  useGetViewsQuery,
  useGetViewMetadataQuery,
  useGetDimensionItemsQuery,
  useGetListItemsQuery,
} = api;
