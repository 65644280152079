import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
const MapPage = lazy(() => import("./MapPage"));
const StatusPage = lazy(() => import("./status/StatusPage"));
const KeplerComponentsPage = lazy(
  () => import("./components/KeplerComponentsPage")
);

function App() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<MapPage />} />
        <Route path="status" element={<StatusPage />} />
        <Route path="sandbox" element={<KeplerComponentsPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
