import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addDataToMap, ActionTypes as KeplerActions } from "@kepler.gl/actions";
import AnaplanDataset, { AddViewPayload } from "./AnaplanDataset";

export type DatasetMeta = Omit<AnaplanDataset, "cellValues">;

interface AnaplanKeplerState {
  /**
   * Config to look up Anaplan coordinates by kepler coordinates.
   *
   * The map tracks points by dataset ID, row & col indices, and the app
   * uses this to convert those back to Anaplan model, module, dimensions,
   * and line item values so it can save data via the Anaplan API.
   */
  viewDatasets: {
    [id: string]: DatasetMeta;
  };
}

const initialState: AnaplanKeplerState = {
  viewDatasets: {},
};

export const addAnaplanView = createAsyncThunk(
  "anaplan.loadData",
  async (payload: AddViewPayload, { dispatch }) => {
    const anaplanDataset = await AnaplanDataset.fromAddViewPayload(payload);

    const keplerDataset = AnaplanDataset.toKeplerDataset(anaplanDataset);

    dispatch(addDataToMap({ datasets: [keplerDataset] }));
    dispatch(addDataset(anaplanDataset));
    return anaplanDataset;
  }
);

const anaplanSlice = createSlice({
  initialState,
  name: "anaplan",
  reducers: {
    addDataset(state, action: PayloadAction<AnaplanDataset>) {
      const { cellValues, ...datasetMeta } = action.payload;
      const id = AnaplanDataset.toDatasetURL(action.payload).href;
      state.viewDatasets[id] = datasetMeta;
    },
  },
  extraReducers: (builder) => {
    builder
      // If a user removes a dataset from the regular Kepler UI,
      // remove the corresponding Anaplan metadata.
      .addCase<string, { key: string; type: string }>(
        KeplerActions.REMOVE_DATASET,
        (state, action) => {
          delete state.viewDatasets[action.key];
        }
      );
  },
});

export const { addDataset } = anaplanSlice.actions;
export default anaplanSlice.reducer;
