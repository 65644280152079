import {
  keplerGlReducer,
  enhanceReduxMiddleware,
  KeplerGlState,
} from "@kepler.gl/reducers";
import {
  configureStore,
  ThunkAction,
  Action,
  PreloadedState,
  StateFromReducersMapObject,
  Reducer,
} from "@reduxjs/toolkit";
import { api } from "./apiSlice";
import anaplan from "../anaplan/anaplanSlice";

const rootReducerMap = {
  keplerGl: keplerGlReducer as Reducer<{
    instance: KeplerGlState;
  }>,
  anaplan,
  [api.reducerPath]: api.reducer,
};

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducerMap,
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      const keplerMiddleware = enhanceReduxMiddleware();
      return getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
        .prepend(api.middleware)
        .concat(keplerMiddleware);
    },
  });

export type RootState = StateFromReducersMapObject<typeof rootReducerMap>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
